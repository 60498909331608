<template>
<div>
  <!-- Breadcrumb Component -->
  <breadcrumb-area :breadcrumb="breadcrumb"/>
  <!-- FAQ Area Component -->
  <legal-guides-area/>
</div>
</template>

<script>
import BreadcrumbArea from "../../components/BreadcrumbArea";
import LegalGuidesArea from "../../components/LegalGuidesArea";
export default {
  name: "LegalGuides",
  components: {LegalGuidesArea, BreadcrumbArea},
  data() {
    return {
      breadcrumb: {
        page: "Legal Guides",
        pageTitle: "Legal Guides"
      }
    }
  }
}
</script>

<style scoped>

</style>