<template>
  <section class="ekifa-blog-page-area section_80">
    <b-container>
      <b-row>
        <div class="blog-page-left">
          <b-row>
            <template v-for="(item, key) in guides">
              <b-col md="12" lg="6" :key="key">
                <div class="single-blog">
<!--                  <div class="blog-img">-->
<!--                    <b-link>-->
<!--                      <img :src="require(`../assets/img/${item.img}`)" alt="blog" />-->
<!--                    </b-link>-->
<!--                  </div>-->
                  <div class="blog-text">
                    <div class="blog-meta">
                      <p><i class="fa fa-clock-o"></i> {{item.date}}</p>
                    </div>
                    <h3><b-link>{{item.title}}</b-link></h3>
                    <p>{{item.description}}</p>
                  </div>
                  <div class="blog-btn">
                    <b-link><i class="fa fa-download"></i></b-link>
                  </div>
                </div>
              </b-col>
            </template>
          </b-row>
        </div>
        <!--Blog Pagination Area-->
<!--        <blog-pagination/>-->
      </b-row>
    </b-container>
  </section>
</template>

<script>
import BlogPagination from "./blog/BlogPagination";
const guides = require('../json/Resources/legal-guides.json')
export default {
  name: "LegalGuidesArea",
  components: {BlogPagination},
  data() {
    return {
      guides : guides
    }
  }
}
</script>

<style scoped>

</style>